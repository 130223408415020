$bg: #FFFFFF;
$snow: #FAFAFA;
$aliceblue: #E8F2F9;
$whitesmoke: #F5F4EF;

//black
$black: #000000;

//blue
$kashmirBlue: #4D66A0;
$matisse: #1C5B9E;
$darkslategray: #283f57;
$midnightblue: #001064;
$purple: #6B147B;

// green
$jungle-mist: #A3CBCA;
$breakerBay: #66A8A6;
$oracle: #377977;
$mediumseagreen: #2ca27d;
$mediumspringgreen: #01d2a2;
$darkcyan: #00838F;

//gray
$gray: #333;
$light-gray: #999999;
$dim-gray: #666666;
$white-smoke: #F4F4F4;
$gray72: #B8B8B8;
$gray90: #E5E5E5;
$gainsboro: #DCDCDC;
$gray-mercury-transparent: hsla(0, 2%, 41%, 0.14);

//red
$red: #d43016;
$light-red: #f17966;
$tomato: #EE6644;
$deeppink: #FF097A;
$brown: #A53939;


//transparent
$blackTransparent8: rgba(0,0,0,0.08);
$blackTransparent10: rgba(0,0,0,0.1);
$blackTransparent16: rgba(0,0,0,0.16);
$blackTransparent25: rgba(0,0,0,0.25);
$blackTransparent30: rgba(0,0,0,0.3);
$blackTransparent50: rgba(0,0,0,0.50);
$blackTransparent60: rgba(0,0,0,0.6);

$whiteTransparent8: rgba(255, 255, 255, 0.08);
$whiteTransparent10: rgba(255, 255, 255, 0.1);
$whiteTransparent16: rgba(255, 255, 255, 0.16);