/* ----- tremor start ----- */
@-webkit-keyframes tremor {
  0%, 25% {
    left: -1px;
    top:-1px;
    -webkit-transform: translateX(-4%) scale(1.5);
    transform: translateX(-4%) scale(1.5);
  }
  50%, 100% {
    left: 1px;
    top: 1px;
    -webkit-transform: translateX(4%) scale(1.4);
    transform: translateX(4%) scale(1.4);
  }
}
@-moz-keyframes tremor {
  0%, 25% {
    left: -1px;
    -webkit-transform: translateX(-4%) scale(1.5);
    transform: translateX(-4%) scale(1.5);
  }
  50%, 100% {
    left: 1px;
    -webkit-transform: translateX(4%) scale(1.4);
    transform: translateX(4%) scale(1.4);
  }
}
@-ms-keyframes tremor {
  0%, 25% {
    left: -1px;
    -webkit-transform: translateX(-4%) scale(1.5);
    transform: translateX(-4%) scale(1.5);
  }
  50%, 100% {
    left: 1px;
    -webkit-transform: translateX(4%) scale(1.4);
    transform: translateX(4%) scale(1.4);
  }
}
@-o-keyframes tremor {
  0%, 25% {
    left: -1px;
    -webkit-transform: translateX(-4%) scale(1.5);
    transform: translateX(-4%) scale(1.5);
  }
  50%, 100% {
    left: 1px;
    -webkit-transform: translateX(4%) scale(1.4);
    transform: translateX(4%) scale(1.4);
  }
}
@keyframes tremor {
  0%, 25% {
    left: -1px;
    -webkit-transform: translateX(-4%) scale(1.5);
    transform: translateX(-4%) scale(1.5);
  }
  50%, 100% {
    left: 1px;
    -webkit-transform: translateX(4%) scale(1.4);
    transform: translateX(4%) scale(1.4);
  }
}
/* ----- tremor end ----- */