@import './fonts.scss';

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: inherit;
  min-height: 100%;
  font-family: RobotoRegular;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
