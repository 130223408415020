@import 'colors.scss';

@mixin themeTextColor($additionalProperty: false) {
  &.default, &.black, &.indigo, &.purple, &.gray  {
    color: $bg;

    @if $additionalProperty {
      #{$additionalProperty}: $bg;
    }
  }

  &.white, &.blue, &.creme {
    color: $dim-gray;

    @if $additionalProperty {
      #{$additionalProperty}: $dim-gray;
    }
  }
}

@mixin themeBackground($lightColor: $bg, $darkColor: $dim-gray) {
  &.default, &.black, &.indigo, &.purple, &.gray  {
    background: $lightColor;
    // background: $bg;
  }

  &.white, &.blue, &.creme {
    // background: $dim-gray;
    background: $darkColor;
  }
}

@mixin label {
  font-family: RobotoRegular;
  font-size: 14px;
  line-height: 16px;
  color: $gray;
  display: block;
}

@mixin placeholder {
  font-size: 16px;
  color: $light-gray;
  opacity: 1;
}

@mixin addRecipeButton {
  font-size: 18px;
  color: $bg;
  background: $breakerBay;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 13px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  max-height: 40px;

  & > svg {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }

  &:hover {
    opacity: 0.9;
  }
}