@import 'styles/colors.scss';

.emptyLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layout {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.default {
    background-color: $darkslategray;
  }

  &.white {
    background-color: $snow;
  }

  &.black {
    background-color: $black;
  }

  &.blue {
    background-color: $aliceblue;
  }

  &.indigo {
    background-color: $midnightblue;
  }

  &.purple {
    background-color: $purple;
  }

  &.creme {
    background-color: $whitesmoke;
  }

  &.gray {
    background-color: $gray;
  }
}

.centerContainer {
  display: flex;
  flex: 1 1 auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 570px) {
  .centerContainer {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .centerContainer {
    position: relative;
  }
}